.container {
    max-width: 960px;
    margin: 0 auto;
}

.banner-bg {
    height: 500px;
    background: url("https://images.unsplash.com/photo-1558976825-6b1b03a03719?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80") no-repeat;
    background-size: cover;
    overflow: hidden;
}
.banner-con {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
}
.banner-text {
    color: #fff;
    text-align: center;
}
.banner-btn {
    background: #fff;
    display: inline-block;
    padding: .5rem 1rem;
    text-decoration: none;
    color: #162B78;
    border: 2px solid transparent;
    transition: 0.3s;
    margin-top: 1rem;
    border-radius: 5px;
    text-transform: uppercase;
}
.banner-btn:hover {
    border: 2px solid #fff;
    background: transparent;
    color: #fff;
}

@media screen and (max-width: 480px) {
    .banner-text {
        padding: 0 2rem;
    }
}