.container {
    max-width: 960px;
    margin: 0 auto;
}
.cta-bg {
    background: url("https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2530&q=80") no-repeat;
    height: 500px;
    background-size: cover;
    background-position: center;
    position: relative;
}
.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6)
}
.cta-text {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 500px;
    text-align: center;
}
.cta-text h2 {
    margin: 0;
}
.FiCode {
    font-size: 2rem;
    margin-bottom: .5rem;
}

.cta-btn {
    background: #fff;
    display: inline-block;
    padding: .5rem 1rem;
    text-decoration: none;
    color: #333;
    border: 2px solid transparent;
    transition: 0.3s;
    margin-top: 1rem;
    border-radius: 5px;
    text-transform: uppercase;
}
.cta-btn:hover {
    border: 2px solid #fff;
    background: transparent;
    color: #fff;
}

@media screen and (max-width: 480px) {
    .cta-text {
        padding: 0 2rem;
    }
}