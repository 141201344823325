.container {
    max-width: 960px;
    margin: 0 auto;
    
}
.content {
    max-width: 960px;
    margin: 0 auto;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: #162B78;
}