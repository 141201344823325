.container {
    max-width: 960px;
    margin: 0 auto;
}
.content-con {
    display: flex;
}
.content-con:first-child, .content-con:last-child {
    padding: 5rem 0;
}
.content-l img {
    max-width: 400px;
    border-radius: 10px;
}
.content-r {
    padding: 2rem;
    text-align: left;
}

@media screen and (max-width: 480px) {
    .content-con {
        flex-direction: column;
    }
    .content-con:nth-child(2) .content-r {
        order: 1;
    }
}