.container {
  max-width: 960px;
  margin: 0 auto;
}
.header {
  z-index: 10;
  background-color: #162b78;
  width: 100%;
  height: 80px;
}

.header-in-main-page{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: none;
  width: 100%;
  height: 80px;
}

.header-con {
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;
}
.logo-container a {
  color: #fff;
  text-decoration: none;
  font-size: rem;
}
.menu {
  display: flex;
  list-style-type: none;
}
.menu li {
  padding-right: 2rem;
}
.menu li a {
  text-decoration: none;
  color: #fff;
}
.mobile-menu {
  display: none;
}

/* kim */

.Resgistor-btn {
  background: #fff;
  display: inline-block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #162b78;
  border: 2px solid transparent;
  transition: 0.3s;
  margin-top: rem;
  border-radius: 8px;
  text-transform: uppercase;
}
.Resgistor-btn:hover {
  border: 2px solid #fff;
  background: transparent;
  color: #fff;
}

@media screen and (max-width: 480px) {
  .mobile-menu {
    display: block;
    color: #fff;
    font-size: 2rem;
  }
  .header {
    padding: 0 2rem;
  }
  .menu {
    width: 100%;
    height: auto;
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 64px;
    left: -100%;
    opacity: 0;
    transition: 0.5s ease;
  }
  .active {
    background: #162b78;
    left: 0;
    opacity: 1;
    z-index: 1;
    padding-left: 0;
    align-content: center;
    transition: 0.5s ease;
  }
  .menu li {
    padding-right: 0;
  }
  .menu-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vw;
    padding: 10px 0;
  }
}
